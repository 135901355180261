import { createContext, useState, useEffect, useReducer } from 'react'

//action - state management
import merchantsReducer from 'store/reducers/merchantsReducer'
import preLaunchMerchantsReducer from 'store/reducers/preLaunchMerchantsReducer'
import merchantsPrivateDataReducer from 'store/reducers/merchantsPrivateDataReducer'
import merchantsCloverReducer from 'store/reducers/merchantsCloverReducer'
import { observeMerchantsDataOfType, MERCHANTS, PRELAUNCH_MERCHANTS, MERCHANTS_PRIVATEDATA, MERCHANTS_CLOVER } from 'store/actions/merchantsActions'

const preApprovedPromoterInitialState = {
    id: null,
    email: null,
    first_name: null,
    last_name: null
}
const promoterInitialState = {
    id: null,
    email: null,
    first_name: null,
    last_name: null
}

const preApprovedMerchantUserInitialState = {
    id: null,
    email: null,
    first_name: null,
    last_name: null
}
const merchantUserInitialState = {
    id: null,
    email: null,
    first_name: null,
    last_name: null,
    merchantIDs: null
}

const merchantsInitialState = []
const preLaunchMerchantsInitialState = []
const merchantsPrivateDataInitialState = []
const merchantsCloverInitialState = []

const FirebaseDatabaseContext = createContext(null)

export const FirebaseDatabaseProvider = ({ children }) => {

    const [preApprovedPromoter, setPreApprovedPromoter] = useState(preApprovedPromoterInitialState)
    const [promoter, setPromoter] = useState(promoterInitialState)

    const [preApprovedMerchantUser, setPreApprovedMerchantUser] = useState(preApprovedMerchantUserInitialState)
    const [merchantUser, setMerchantUser] = useState(merchantUserInitialState)

    const [merchants, merchantsDispatch] = useReducer(merchantsReducer, merchantsInitialState)
    const [preLaunchMerchants, preLaunchMerchantsDispatch] = useReducer(preLaunchMerchantsReducer, preLaunchMerchantsInitialState)
    const [merchantsPrivateData, merchantsPrivateDataDispatch] = useReducer(merchantsPrivateDataReducer, merchantsPrivateDataInitialState)
    const [merchantsClover, merchantsCloverDispatch] = useReducer(merchantsCloverReducer, merchantsCloverInitialState)

    useEffect(
        () => {
            if (merchantUser.merchantIDs) {
                // observe merchants
                observeMerchantsDataOfType(MERCHANTS, merchantUser.merchantIDs, (callbackMerchantsDispatch) => {
                    merchantsDispatch(callbackMerchantsDispatch)
                })
                // observe preLaunchMerchants
                observeMerchantsDataOfType(PRELAUNCH_MERCHANTS, merchantUser.merchantIDs, (callbackPreLaunchMerchantsDispatch) => {
                    preLaunchMerchantsDispatch(callbackPreLaunchMerchantsDispatch)
                })
                // observe merchantPrivateData
                observeMerchantsDataOfType(MERCHANTS_PRIVATEDATA, merchantUser.merchantIDs, (callbackMerchantsPrivateDataDispatch) => {
                    merchantsPrivateDataDispatch(callbackMerchantsPrivateDataDispatch)
                })
                // observe merchantsClover
                observeMerchantsDataOfType(MERCHANTS_CLOVER, merchantUser.merchantIDs, (callbackMerchantCloverDispatch) => {
                    merchantsCloverDispatch(callbackMerchantCloverDispatch)
                })
            }
        }, [merchantUser]
    )

    const returnAllMerchants = () => {
        let allMerchantsArray = []
        merchants.forEach((merchant) => allMerchantsArray.push(merchant))
        preLaunchMerchants.forEach((preLaunchMerchant) => allMerchantsArray.push(preLaunchMerchant))
        return allMerchantsArray
    }
    
    return (
        <FirebaseDatabaseContext.Provider
            value={{
                preApprovedPromoter,
                setPreApprovedPromoter,
                promoter,
                setPromoter,
                preApprovedMerchantUser,
                setPreApprovedMerchantUser,
                merchantUser,
                setMerchantUser,
                merchants,
                preLaunchMerchants,
                allMerchants: returnAllMerchants(),
                merchantsPrivateData,
                merchantsClover
            }}
        >
            {children}
        </FirebaseDatabaseContext.Provider>
    )
}

export default FirebaseDatabaseContext