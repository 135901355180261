import { lazy } from 'react'

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard'
import MinimalLayout from 'layout/MinimalLayout'
import NavMotion from 'layout/NavMotion'
import Loadable from 'ui-component/Loadable'

// login routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')))
const AuthRegister = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')))
const AuthForgotPassword = Loadable(lazy(() => import('views/pages/authentication/authentication3/ForgotPassword3')))
const PreApprove = Loadable(lazy(() => import('views/pages/authentication/authentication3/PreApprove3')))
const AuthRegisterPromoter = Loadable(lazy(() => import('views/pages/authentication/authentication3/RegisterPromoter3')))

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/login/:oauth_optional/:pos_integration',
            element: <AuthLogin />
        },
        {
            path: '/register',
            element: <AuthRegister />
        },
        {
            path: '/forgot',
            element: <AuthForgotPassword />
        },
        {
            path: '/preapprove',
            element: <PreApprove />
        },
        {
            path: '/register-promoter',
            element: <AuthRegisterPromoter />
        }
    ]
};

export default LoginRoutes
