import dashboard from './dashboard'
import account from './account'
import other from './other'
import pointOfSale from './pointofsale'

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, account, pointOfSale, other]
}

export default menuItems
