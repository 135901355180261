import { httpsCallable } from 'firebase/functions'

import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, TextField, Paper, Box, Button } from '@mui/material';

import useDatabase from 'hooks/useDatabase'
import { updateCloverItems } from 'store/actions/merchantsActions'
import { cloudfunctions } from 'firebase/firebase'

const CloverMenuItems = () => {
  // Sample data
  const [data, setData] = useState([]);

  const { allMerchants, merchantsClover } = useDatabase()
  const [merchantIds, setMerchantIds] = useState([])

  useEffect(() => {
    const getMenuItems = async () => {
        if (merchantIds.length > 0) {
            const merchantId = merchantIds[0]
            console.log('getMenuItems - merchantId', merchantId)
            const pos_clover_get_menu_items = httpsCallable(cloudfunctions, 'pos_clover_get_menu_items')
            const menuItems = await pos_clover_get_menu_items({ merchantId: merchantId })
            console.log('menuItems', menuItems)
        }
    }
    getMenuItems()
  }, [merchantIds])

  useEffect(() => {
    let newMerchantIds = []
    for (const merchant of allMerchants) {
        newMerchantIds.push(merchant.id)
    }
    setMerchantIds(newMerchantIds)
  }, [allMerchants])

  useEffect(() => {
    if (merchantsClover.length > 0) {
        const cloverData = merchantsClover[0]
        const keys = Object.keys(cloverData)
        let newData = []
        for (const key of keys) {
            if (key !== "id") {
                const oldObj = cloverData[key]
                const newObj = { ...oldObj, id: key }
                newData.push(newObj)
            }
        }
        setData(newData)
    }
  }, [merchantsClover])

  const handleToggleVisibility = (id) => {
    setData(prevData =>
      prevData.map(item => {
        const newVal = !item.isVisible
        return item.id === id ? { ...item, isVisible: newVal, overrideName: item.overrideName, displayName: item.displayName } : item
      })
    );
  };

  const handleOverrideNameChange = (id, newName) => {
    setData(prevData =>
      prevData.map(item =>
        item.id === id ? { ...item, overrideName: newName } : item
      )
    );
  };

  const handleSave = () => {
    const transformedData = data.reduce((acc, item) => {
        // Only add the item if it's visible
        const newDisplayName = item.isVisible ? (item.overrideName ? item.overrideName : item.name) : ''
        acc[item.id] = { name: item.name, overrideName: item.overrideName, displayName: newDisplayName, isVisible: item.isVisible }
        return acc;
      }, {});
    updateCloverItems(merchantIds, transformedData)
  }

  return (
    <Box>
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: 'auto' }}>Toggle Item Visibility</TableCell>
            <TableCell sx={{ width: 'auto' }}>Clover Menu Item Name</TableCell>
            <TableCell sx={{ width: 'auto' }}>Override Name</TableCell>
            <TableCell sx={{ width: 'auto' }}>Displayed Name In App</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <TableRow key={item.id}>
              <TableCell sx={{ width: 200 }}>
                <Checkbox
                  checked={item.isVisible}
                  onChange={() => handleToggleVisibility(item.id)}
                />
              </TableCell>
              <TableCell sx={{ width: 300 }}>{item.name}</TableCell>
              <TableCell sx={{ width: 300 }}>
                <TextField
                  value={item.overrideName}
                  onChange={(e) => handleOverrideNameChange(item.id, e.target.value)}
                  fullWidth
                />
              </TableCell>
              <TableCell sx={{ width: 300 }}>{item.displayName}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    {/* Save Button */}
    <Box sx={{ marginTop: 2, textAlign: 'center' }}>
        <Button variant="contained" color="primary" onClick={handleSave} style={{width: '30%'}}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default CloverMenuItems;
