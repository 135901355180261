// third-party
import { ref, onValue, off, update } from 'firebase/database'

// project imports
import { database } from 'firebase/firebase'

// action - merchants reducer
export const SET = 'SET'
export const UPDATE = 'UPDATE'
export const REMOVE = 'REMOVE'

// merchants data type
export const MERCHANTS = 'MERCHANTS'
export const PRELAUNCH_MERCHANTS = 'PRELAUNCH_MERCHANTS'
export const MERCHANTS_PRIVATEDATA = 'MERCHANTS_PRIVATEDATA'
export const MERCHANTS_CLOVER = 'MERCHANTS_CLOVER'

// merchants updates type
export const MENU_LINK = 'MENU_LINK'
export const DESCRIPTION = 'DESCRIPTION'

// ==============================|| MERCHANTS & PRELAUNCHMERCHANTS & MERCHANTS-PRIVATE-DATA METHODS ||============================== //

export const observeMerchantsDataOfType = (merchantDataType, merchantIDs, callback) => {
    // determine which merchant data type to read from
    let rootRefDataType = 'merchants'
    switch (merchantDataType) {
        case MERCHANTS: {
            rootRefDataType = 'merchants'
            break
        }
        case PRELAUNCH_MERCHANTS: {
            rootRefDataType = 'preLaunchMerchants'
            break
        }
        case MERCHANTS_PRIVATEDATA: {
            rootRefDataType = 'merchantPrivateData'
            break
        }
        case MERCHANTS_CLOVER: {
            rootRefDataType = 'merchantsClover'
            break
        }
        default:
            console.log('no merchant data type selected')
            return
    }

    let promiseAllArray = []
    merchantIDs.forEach((merchantID) => {
        const merchantRef = ref(database, `${rootRefDataType}/${merchantID}`)
        // detach listener first
        off(merchantRef, 'value')

        let isPartOfInitialObserve = true
        const merchantPromise = new Promise((resolve, reject) => {
            onValue(merchantRef, (snapshot) => {
                if (snapshot.exists()) {
                    const snapshotResults = snapshot.val()
                    const merchant = {
                        ...snapshotResults,
                        id: snapshot.key
                    }
                    // add isPreLaunch property if MERCHANTS or PRELAUNCH_MERCHANTS
                    switch (merchantDataType) {
                        case MERCHANTS: {
                            merchant.isPreLaunch = false
                            break
                        }
                        case PRELAUNCH_MERCHANTS: {
                            merchant.isPreLaunch = true
                            break
                        }
                        default:
                            break
                    }
                    // determine when callback is called
                    if (isPartOfInitialObserve) {
                        isPartOfInitialObserve = false
                        resolve(merchant)
                    } else {
                        const merchantsAction = {
                            type: UPDATE,
                            payload: merchant
                        }
                        callback(merchantsAction)
                    }
                } else {
                    // determine when callback is called
                    if (isPartOfInitialObserve) {
                        isPartOfInitialObserve = false
                        resolve(null)
                    } else {
                        const merchantsAction = {
                            type: REMOVE,
                            payload: merchantID
                        }
                        callback(merchantsAction)
                    }
                }
            })
        })
        promiseAllArray.push(merchantPromise)
    })

    Promise.all(promiseAllArray).then((merchants) => {
        const merchantsWithNoNulls = merchants.filter((merchant) => (merchant))
        const merchantsAction = {
            type: SET,
            payload: merchantsWithNoNulls
        }
        callback(merchantsAction)
    })
}

// ==============================|| MERCHANTS & PRELAUNCHMERCHANTS METHODS ||============================== //

export const updateCloverItems = (merchantIds, cloverItems) => {
    for (const merchantId of merchantIds) {
        const cloverRef = ref(database, `merchantsClover/${merchantId}`)
        update(cloverRef, cloverItems)
    }
}

export const updateMerchantDetails = (merchant, updateType, textToUpdate) => {
    // determine which merchant data type to update
    let rootRefDataType = ''
    switch (merchant.isPreLaunch) {
        case true: {
            rootRefDataType = 'preLaunchMerchants'
            break
        }
        case false: {
            rootRefDataType = 'merchants'
            break
        }
        default: {
            return
        }
    }
    // determine property to update
    let propertyToUpdate = ''
    switch (updateType) {
        case MENU_LINK: {
            propertyToUpdate = 'menuLink'
            break
        }
        case DESCRIPTION: {
            propertyToUpdate = 'description'
            break
        }
        default:
            return
    }
    // update merchant
    const merchantID = merchant.id
    if (!merchantID) {
        return
    }
    const merchantPropertyRef = ref(database, `${rootRefDataType}/${merchantID}`)
    update(merchantPropertyRef, {
        [propertyToUpdate]: textToUpdate
    })
}

export const updateMerchantIncentives = (merchant, newIncentives, callback) => {
    // determine which merchant data type to update
    let rootRefDataType = ''
    switch (merchant.isPreLaunch) {
        case true: {
            rootRefDataType = 'preLaunchMerchants'
            break
        }
        case false: {
            rootRefDataType = 'merchants'
            break
        }
        default: {
            return
        }
    }
    // update merchant
    const merchantID = merchant.id
    if (!merchantID) {
        return
    }
    const merchantIncentivesRef = ref(database, `${rootRefDataType}/${merchantID}`)
    update(merchantIncentivesRef, {
        rewardsPlan: newIncentives
    }).then(() => {
        callback()
    })
}