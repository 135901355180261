import { createContext, useState } from 'react'

const cloverDetailsInitialState = {
    pos_integration: null,
    merchant_id: null,
    client_id: null,
    code: null
}

const PosIntegrationContext = createContext(null)

export const PosIntegrationProvider = ({ children }) => {

    const [cloverDetails, setCloverDetails] = useState(cloverDetailsInitialState)
    
    return (
        <PosIntegrationContext.Provider
            value={{
                cloverDetails,
                setCloverDetails
            }}
        >
            {children}
        </PosIntegrationContext.Provider>
    )
}

export default PosIntegrationContext