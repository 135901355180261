// third-party
import { FormattedMessage } from 'react-intl'

// assets
import ReceiptIcon from '@mui/icons-material/Receipt'
import MenuIcon from '@mui/icons-material/Menu'

// ==============================|| POINT-OF-SALE MENU ITEMS ||============================== //

const pointOfSale = {
    id: 'point-of-sale',
    title: <FormattedMessage id="point-of-sale"/>,
    type: 'group',
    children: [
        {
            id: 'connect-with-clover',
            title: <FormattedMessage id="connect-with-clover"/>,
            type: 'item',
            url: 'connect-with-clover',
            icon: ReceiptIcon,
            breadcrumbs: false
        },
        {
            id: 'clover-menu-items',
            title: <FormattedMessage id="clover-menu-items"/>,
            type: 'item',
            url: 'clover-menu-items',
            icon: MenuIcon,
            breadcrumbs: false
        }
    ]
}

export default pointOfSale